<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-01 14:34:50
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-01-10 14:35:50
 * @FilePath: \web\src\views\invoicing\batchElectronicInvoice\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="app-container">
    <div class="tips">
      <div class="text">
        <p>注意：</p>
        <p>导入数据时请严格按照模板格式导入</p>
      </div>
    </div>
    <div class="content">
      <div class="headline">导入步骤：</div>
      <!-- <p class="firstStep">第一步：请点击下面的链接下载Excel模板，并按照模板填写日记账信息</p> -->
      <p >点击下载 <span class="Import" @click="upload()" v-auth='"batchElectronicInvoice:template"'>批量导入模板</span></p>
      <p>选择Excel数据文件</p>
      <div class="upload-box">
        <el-upload class="upload-demo" ref="upload" accept=".xls,.xlsx" action="#" 
        :on-change="handleChange" :before-upload='beforeUpload' :http-request="httpRequest"
        :on-preview="handlePreview" :on-remove="handleRemove" 
        drag
        :file-list="fileList" :auto-upload="false" >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <!-- <span slot="trigger" class="upload">选取文件</span> -->
        </el-upload>
        <el-button type="primary" class="btn" @click="importConfirm" :loading='isLoading' v-auth='"batchElectronicInvoice:submit"'>确定开票</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import XLSX from "xlsx";

  export default {
    data() {
      return {
        fileList: [],
        fileData: '',
        isLoading: false,
        tableData: [],
        status:  false,
        message : '请选择上传文件',
      };
    },
    methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        this.fileList = fileList.slice(-1);  //限定上传文件为一个
      },
      handleRemove(file, fileList) {
        this.fileData = '';
        this.status = false;
        this.message = '请选择上传文件';
      },
      beforeUpload(file, fileList) {
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'xls'
        const extension2 = testFile === 'xlsx'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if(!extension && !extension2) {
          this.message = '上传文件只能是xls或xlsx格式！'
          this.status = false;
        }else if (!isLt1M) {
          this.message = '上传Excel文件大小不能超过 1MB!'
          this.status = false;
        }else{
          let fd = new FormData()
          fd.append('file', file)
          fd.append('companyId', this.getToken('companyId'))
          fd.append('userId', this.getToken('userId'))
          this.fileData = fd;
          this.status = true;
        }
      },
      handlePreview(file) {
        console.log(file);
      },
      importConfirm() {
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.tableData = [];
        this.$refs.upload.submit()
        if (this.fileData && this.fileData != ''&& this.status) {
          this.isLoading = true;
          this.$store.dispatch("ImportIssue", this.fileData).then(res => {
            if (res.success) {
              this.isLoading = false;
              this.fileList = [];
              this.fileData = '';
              this.status = false;
              this.message = '请选择上传文件';
              var reason = ''
              if (  res.data && res.data.failNum && res.data.failNum > 0 ) {
                var i = 0;
                res.data.failReason.forEach(each => {
                  i++;
                  if (i < 5) {
                    reason = reason.concat(`<p style="padding-top:0.5rem">第${each.index}行${each.reason},</p>`)
                  }
                  if (i == 5) {
                    reason = reason.concat(`<p style="padding-top:0.5rem">...</p>`)
                  }
                  this.tableData.push({
                    错误行: "第" + each.index + "行",
                    错误信息: each.reason
                  });
                })
                //有错误信息将错误信息通过excel导出
                if (this.tableData.length > 0) {
                  this.json2Excel(this.tableData)
                }
              }
              // this.$message.success({
              //   duration: 3000,
              //   dangerouslyUseHTMLString: true,
              //   message: msg
              // });
              einvAlert.success('提示',res.msg)
            } else {
              this.isLoading = false;
              this.fileList = [];
              this.fileData = '';
              einvAlert.warning('提示',res.msg)
              // this.$message.error(res.msg);
            }
          })
        } else {
          this.isLoading = false;
          this.fileList = [];
          this.fileData = '';
          einvAlert.warning('提示',this.message)
          // this.$message.error(this.message);
          return;
        }
      },

      upload() {  //下载日记账导入模板
        let name = '开票批量导入模板.xls'
        axios({
          method: 'get',
          url: '/api/sks/invoice/downloadTemplate',
          responseType: 'blob',
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then((res) => {
          let data = res.data;
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
      json2Excel(dataSource) {
        var wopts = {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        };
        var workBook = {
          SheetNames: ['Sheet1'],
          Sheets: {},
          Props: {}
        };
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(dataSource)
        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        this.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], { type: 'application/octet-stream' }), "错误信息")
      },
      changeData(s) {
        //如果存在ArrayBuffer对象(es6) 最好采用该对象
        if (typeof ArrayBuffer !== 'undefined') {
          //1、创建一个字节长度为s.length的内存区域
          var buf = new ArrayBuffer(s.length);
          //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
          var view = new Uint8Array(buf);
          //3、返回指定位置的字符的Unicode编码
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        } else {
          // eslint-disable-next-line no-redeclare
          var buf = new Array(s.length);
          // eslint-disable-next-line no-redeclare
          for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
      },
      saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式
        var tmpa = document.createElement("a");
        tmpa.download = fileName ? fileName + '.xls' : new Date().getTime() + '.xls';
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
          URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
      },

    }
  };
</script>

<style lang="less" scoped>
  .tips{
    background: #fff;
    overflow: hidden;
    padding: 10px 30px ;
    .text{
      margin: 20px auto;
      height: 106px;
      border: 2px dashed #f2b91c;
      padding-top: 20px;
      p{
        text-indent: 20px;
        line-height: 30px;
        font-size: 15px;
        margin: 0;
      }
    }
  }
  .content{
    background: #fff;
    margin-top: 30px;
    height: 600px;
    padding: 10px 30px;
    .headline {
      padding: 18px 0 10px 16px;
      position: relative;
      font-size: 18px;
      &::after {
        content: "";
        width: 4px;
        height: 16px;
        background-color: #4f71ff;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .firstStep {
      margin: 24px 0 12px;
    }
    p{
      font-size: 16px;
    }
    .Import{
      color: #F2781C;
      cursor: pointer;
    }
    .upload {
      font-size: 16px;
      cursor: pointer;
      color: #4f71ff;
    }
    .upload-box{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: space-around;
      justify-content: center;
      align-items: center;
    }
    .btn{
      margin-top: 30px;
    }
  }
</style>